<!-- New Component Name: Category Slider, CategorySlider -->

<template>
  <div class="explore-slider">
    <VaimoHeading
      v-if="heading"
      :heading="heading"
      :heading-link="sectionData.headingLink"
      class="module__heading mb-md px-sm text-center"
    />
    <div>
      <VaimoTabs v-if="tabs && tabs.length" :tabs="tabs">
        <template v-for="tab in tabs" #[tab.id]>
          <VaimoSlider
            v-if="tab.data.categories && tab.data.categories.length"
            :key="`tab__slider__${tab.id}`"
            :desktop-quantity-visible="4"
            :desktop-shift="0"
            :mobile-shift="25"
            :items-length="tab.data.categories.length"
            :ignore-center-mode="true"
          >
            <div
              v-for="category in tab.data.categories"
              :key="category.id"
              class="explore-slider__category"
            >
              <VaimoBanner
                :src="getCategoryImage(category)"
                :src-hover="getCategoryHoverImage(category)"
                :link="getCategoryLink(category)"
                ratio="3:4"
                :max-optimized-width="500"
                class="explore-slider__category-banner mb-md"
              />

              <h4 class="explore-slider__category-heading mb-xs">
                {{ category.name }}
              </h4>

              <VaimoTruncatedText
                v-if="isEnabled('Description') && category.description"
                :text="category.description"
                truncate="by lines"
                :lines="3"
                class="explore-slider__category-description mb-xs"
              />

              <div
                v-if="isEnabled('Products count')"
                class="explore-slider__category-count mb-xs"
              >
                {{ category.product_count }} {{ $t('Products') }}
              </div>

              <div
                v-if="isEnabled('Minimal price') && category.products_min_price"
                class="explore-slider__category-minprice mb-xs m-and-l:mt-md"
              >
                {{ getDynamicPriceText(getCategoryMinPrice(category)) }}
              </div>

              <div class="explore-slider__category-link-wrap m-and-l:mt-md">
                <SfLink
                  :link="getCategoryLink(category)"
                  class="explore-slider__category-link"
                >
                  {{ $t('See all') }}
                </SfLink>
              </div>
            </div>
          </VaimoSlider>
        </template>
      </VaimoTabs>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useAlternativeVisual, useLink } from '~/diptyqueTheme/composable';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { addBasePath } from '~/helpers/addBasePath';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';

export default defineComponent({
  name: 'ExploreSlider',
  components: {
    VaimoSlider: () => import('molecules/VaimoSlider.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoTabs: () => import('molecules/VaimoTabs.vue'),
    SfLink
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const {
      app: { i18n }
    } = useContext();
    const { flatCategoryArray, categoryTree, categoryStore, loadCategoryTree } =
      useTraverseCategory();
    const { normalizeLink } = useLink();
    const { query, queryResponse } = useContentfulGQL('exploreSlider');
    const { applyAltCategoryVisuals } = useAlternativeVisual();
    const heading = computed(() => props.sectionData?.heading);

    const getCategoryDataById = (id, category = null) => {
      id = parseInt(id);

      if (!category) {
        category = categoryTree.value;
      }

      if (!category) {
        return null;
      }

      if (category.id === id) {
        return category;
      }

      if (!category.id && Array.isArray(category)) {
        return category.find((categoryItem) => categoryItem.id === id);
      }

      // FIXME should here be used instead useTraverseCategory.activeCategory?
      if (category.children && category.children.length > 0) {
        for (let i = 0; i < category.children.length; i++) {
          const foundCategory = getCategoryDataById(id, category.children[i]);

          if (foundCategory) {
            return foundCategory;
          }
        }
      }

      return null;
    };

    const getTabCategories = (group, rootCategory) => {
      let categories =
        group.cids
          ?.map((cid) => getCategoryDataById(cid, rootCategory))
          .filter((c) => c) || [];

      if (group?.altvisCollection?.items?.length) {
        categories = applyAltCategoryVisuals(
          JSON.parse(JSON.stringify(categories)),
          group?.altvisCollection?.items
        );
      }

      return categories;
    };

    const tabs = computed(() => {
      const rootCategory = flatCategoryArray.value;
      if (!rootCategory) {
        return [];
      }

      return (
        props.sectionData?.groupsCollection?.items?.map((group, i) => ({
          title: group.groupTitle,
          id: 'tab' + i,
          data: {
            categories: getTabCategories(group, rootCategory)
          }
        })) || []
      );
    });

    const getCategoryImage = (category) => {
      return (
        category?.image ||
        queryResponse.value?.assetCollection?.items?.[0]?.url ||
        addBasePath('/category/category-image--default.png')
      );
    };

    const getCategoryHoverImage = (category) => {
      return category.image_hover ?? null;
    };

    const getCategoryLink = (category) => {
      return normalizeLink('/l/' + category.url_path + category.url_suffix);
    };

    const getCategoryMinPrice = (category) => {
      return validateFraction(category.products_min_price);
    };

    const getDynamicPriceText = (price) => {
      return i18n.t('Starting from {price}', {
        price
      });
    };

    const isEnabled = (prop) => {
      return (
        props.sectionData.visibility &&
        props.sectionData.visibility.includes(prop)
      );
    };

    const getDefaultImage = async () => {
      try {
        await query('getAssetByTitle', {
          title: 'explore_slider_default_image',
          locale: 'en'
        });
      } catch (e) {
        console.error(e);
      }
    };

    const sectionDataFetchPromises = [
      getDefaultImage(),
      categoryStore?.rawCategories ? Promise.resolve() : loadCategoryTree()
    ];

    useFetch(async () => {
      await Promise.all(sectionDataFetchPromises);
    });

    return {
      heading,
      tabs,
      getCategoryImage,
      getCategoryHoverImage,
      getCategoryLink,
      getCategoryMinPrice,
      getDynamicPriceText,
      isEnabled
    };
  }
});
</script>

<style lang="scss" scoped>
.explore-slider {
  text-align: center;

  &__category-heading {
    font-family: var(--font-family--secondary);
    font-size: 16px;
    font-weight: 400;
  }

  &__category-count {
    color: var(--c-gris-perle);
    font-size: 14px;
    font-weight: 300;
    text-transform: lowercase;

    @include for-screen-s {
      font-size: 12px;
    }
  }

  &__category-minprice {
    font-size: 14px;
    font-weight: 400;
  }

  &__category-link {
    font-family: var(--font-family--primary);
    font-size: 14px;
    font-weight: 400;
  }

  &__category-description {
    font-size: 14px;
    font-weight: 300;

    ::v-deep {
      a {
        display: none !important;
      }
    }

    @include for-screen-s {
      font-size: 12px;
    }
  }

  ::v-deep {
    .vaimo-slider__slider {
      @apply px-md;

      @include for-screen-m-and-l {
        @apply px-lg;
      }
    }
  }
}
</style>
